import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout.js";

const Ben = () => {
  return (
    <Layout>
      <Head title="Benjamin Horselips" />
      <h3>Benjamin Horselips</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Vancouver, WA
      </h4>
      <p>
        <OutboundLink href="https://www.youtube.com/channel/UCq8l0FL7duGbxibXyizuQ3Q">
          YouTube
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="thebenortlip@carrd.co">Socials</OutboundLink>
      </p>
      <p>
        Ben Ortlip is a stand up comedian in the vancouver washington/pacific
        northwest area. Originally from Pennsylvania, he performs stand up
        comedy to crowds old and young weaving the incredible story of his life
        with jokes, puns and poignant reflections.
      </p>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/wpSZ617-DKM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </Layout>
  );
};

export default Ben;
